import type { UIMatch } from '@remix-run/react';
import { useMatches } from '@remix-run/react';
import { useMemo } from 'react';
import type { APHandle } from '../type';

/**
 * This base hook is used to quickly search for specific handle data
 * across all loader data using useMatches.
 */

export const useMatchesHandleData = <T>(
    key: keyof APHandle,
    defaultValue: T,
    latestMatchFirst = true
): T => {
    const matches = useMatches();
    const value = useMemo(() => {
        const newMatches = latestMatchFirst ? matches.reverse() : matches;
        const match = newMatches.find(
            (match) => (match.handle as APHandle)?.[key] !== undefined
        ) as UIMatch<unknown, APHandle>;

        return match?.handle?.[key] ?? defaultValue;
    }, [matches, key, defaultValue, latestMatchFirst]);
    return value as T;
};
